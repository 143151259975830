import * as React from 'react';

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import {extractMonth, extractDay} from '../utils.tsx'
import CharismaRating from '../components/CharismaRating.tsx'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import StarIcon from '@mui/icons-material/Star';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';



export default function CharismaStudentTrainingAssessmentForm(props){
    
    interface item{
        id:number,
        type: number,
        title: string,
        note:number
    }
    
    const [partComment, setPartComment] = React.useState(null);
    const [partId, setPartId] = React.useState(0);
    const [items, setItems] = React.useState([]);
    const [id, setId] = React.useState(0);
    const [date, setDate] = React.useState('');
    const [readOnly, setReadOnly] = React.useState(props.readOnly)
    const [wait, setWait] = React.useState(false);
    const [hoverIndex, setHoverIndex] = React.useState(-1);
    
    React.useEffect(()=>{
        const lParts = props.assessment.parts.filter((part)=>(part.profile=== props.profile)); 
        if(lParts.length > 0){
            setPartComment(lParts[0].comment);
            setItems(lParts[0].items);
            setPartId(lParts[0].id);
            setReadOnly(props.readOnly)
        }
        else{
            setItems([]);
        }
        setDate(props.assessment.date);
        setId(props.assessment.id);
    }, [props.assessment, props.profile, props.readOnly])   
    
    
    function handleSubmit(pItems : item[]){
        setWait(true);
        props.handleSubmit(items, partComment, partId, id);
    }
    
    function handleChange(pItemId : number, pValue : number){
        let lItems = items.slice();
        for(let lIndex = 0; lIndex < lItems.length; lIndex++){
            let lItem = lItems[lIndex];
            if(lItem.id === pItemId){
                lItem.value = pValue;
            } 
        }
        console.log(pItemId)
        setItems(lItems);
    }

    function handleChangeComment(pItemId : number, pComment : string){
        let lItems = items.slice();
        for(let lIndex = 0; lIndex < lItems.length; lIndex++){
            let lItem = lItems[lIndex];
            if(lItem.id === pItemId){
                lItem.comment = pComment;
            } 
        }
        setItems(lItems);
    }

    function createTitle(){
        let lTitle = "";
        if(props.type === 0){
            lTitle = "Bilan du " + extractDay(date);
        }
        if(props.type === 1){
            lTitle = "Bilan " + extractMonth(date);
            if(props.profile === 1){
                lTitle = lTitle + "(OF)";
            } 
            else{
                lTitle = lTitle + "(Tutorat)";
            }
        }
        return( 
            <DialogTitle>
                {lTitle}
            </DialogTitle>
        );
    }
       
    
    function createItem(pItem : item){
        if(pItem.type === 0){
            return(
                <Box sx={{m:'10px'}}>
                    <CharismaRating
                        value={pItem.value}
                        id={pItem.id}
                        onChange={handleChange}
                        max={5}
                        readOnly = {readOnly}
                    />
                </Box>  
            );
        }
        else if(pItem.type === 1){
            return(
                <Box>
                <TextField
                    id="partComment"
                    name="partComment"
                    fullWidth
                    multiline
                    disabled = {readOnly}
                    InputLabelProps={{ shrink: true}}
                    rows={5}
                    value={pItem.comment}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            handleChangeComment(pItem.id,event.target.value);
                        }}
                />                
                </Box>
            )
        }
    }
    
    function createItemsTable(){
        return(
            <Table>
                <TableBody>
                    {
                        items.map((item)=>(
                            <TableRow>
                                <TableCell>
                                    {item.title}
                                </TableCell>
                                <TableCell>
                                    {createItem(item)}
                                </TableCell>
                            </TableRow>  
                        ))
                    }
                    {props.type === 1 ?
                        <TableRow>
                            <TableCell colSpan={2}>
                                {createPartComment()}
                            </TableCell>
                        </TableRow>
                        :
                        null
                    }
                </TableBody>
            </Table>
        )
    }
        
    
    function createPartComment(){
        return(
            <TextField
                id="partComment"
                name="partComment"
                fullWidth
                multiline
                disabled = {readOnly}
                label="Commentaire"
                InputLabelProps={{ shrink: true}}
                rows={5}
                value={partComment}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setPartComment(event.target.value);
                    }}
            />
        )
    }
                       
    return(
        <Dialog
            open={true}
            fullWidth
            maxWidth={'sm'}
        >
            
            {createTitle()}     
            <DialogContent>         
                {
                    createItemsTable()
                }
                {wait ?
                    <Alert severity="info">{"Validation en cours"}</Alert>
                    :
                    null
                }
            </DialogContent>
            <DialogActions>
                {readOnly ?
                    <Button onClick={()=>props.handleCancel()}>OK</Button>
                    :
                    <div>
                    <Button disabled={wait} onClick={()=>handleSubmit()}>Valider</Button>                
                    <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
                    </div>
                }
            </DialogActions>
        </Dialog>        
    );
}