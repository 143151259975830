import * as React from 'react';

import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader';
import { CharismaUserContext } from '../context.tsx';
import CharismaStudentTrainingDocsForm from './studentTrainingDocsForm.tsx';
import {CharismaStudentTrainingApi} from '../api/studentTrainings/CharismaStudentTrainingApi.tsx';
import {CharismaTrainingApi} from '../api/trainings/CharismaTrainingApi.tsx';
import Checkbox from '@mui/material/Checkbox'
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase'
import { Link } from 'react-router-dom';
import {formatDate} from '../utils.tsx'
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography';


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));


export default function CharismaStudentTrainingDocs(props){
    
    const lType = props.type;
    const lStudentTrainingId = props.studentTrainingId;
    const lTrainingId = props.trainingId;
    const lStudentId = props.studentId;
    
    const [add, setAdd] = React.useState(false);
    const [download, setDownload] = React.useState(false);
    const [link, setLink] = React.useState(null);
    const [deleteDocuments, setDeleteDocuments] = React.useState(false);
    const [documents, setDocuments] = React.useState([]);
    const [docTypes, setDocTypes] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [search, setSearch] = React.useState('');
    const [reminderSent, setReminderSent] = React.useState(false);
    
    const lCurrentUser = React.useContext(CharismaUserContext);
    const lReadWrite = !props.readOnly;
              
       
    
    React.useEffect(()=>{
        const lResponse = CharismaStudentTrainingApi.ListDocument(lStudentTrainingId, lType);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.documents !== null){
                    setDocuments(value.documents);
                }
                
            }
        }).catch((error)=>{
            
        })
        setSelected([]);
        if(lType === 'subscribe'){
            const lResponseTypes = CharismaTrainingApi.ListStudentDocument(lTrainingId)
            lResponseTypes.then((value)=>{
            if(value.error === 0){
                if(value.documents !== null){
                    setDocTypes(value.documents);
                }
                
            }
        }).catch((error)=>{
            
        })
        }
    }, [lStudentTrainingId, lType, lTrainingId])
    
    
    function handleSelectedDocument(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number){
        let lNewSelected : number[] = [];
        let lIndex = selected.indexOf(pId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selected, pId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selected.slice(1));
            }
            else if(lIndex === selected.length - 1){
                lNewSelected = lNewSelected.concat(selected.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selected.slice(0, lIndex), selected.slice(lIndex + 1),);
            }
        }
        setSelected(lNewSelected);
    }
    
    function handleDownload(){
        setDownload(true);
        const lResponse = CharismaStudentTrainingApi.DownloadZip(lStudentTrainingId, lStudentId, lType, props.studentName);
        lResponse.then((value)=>{
            if(value.error === 0){
                const lLink = value["link"];
                setLink(lLink);
            }
            else{
                
            }
        }).catch((error)=>{})
    }
    
    function handleValid(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number){
        let lValue = 0;
        pEvent.target.checked ? lValue = 1 :lValue = 0;
        const lResponse = CharismaStudentTrainingApi.UpdateValidity(pId, lValue, lStudentTrainingId, lStudentId, lTrainingId);
        lResponse.then((value)=>{
            if(value.error === 0){
                const lValid = value["valid"];
                const lId = value["documentId"];
                let lDocuments = documents.slice();
                for(let lIndex = 0; lIndex < lDocuments.length ; lIndex++){
                    if(lDocuments[lIndex].id === lId){
                        lDocuments[lIndex].valid=lValid;
                        break;
                    }
                }
                setDocuments(lDocuments);
            }
            else{
                
            }
        }).catch((error)=>{})
        
    }
    
    function handleDeleteDocuments(){
        const lResponse = CharismaStudentTrainingApi.DeleteDocuments(selected);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.ids !== null){
                    const lIds = value.ids;
                    let lDocuments = documents.filter((document)=>(lIds.indexOf(document.id) === -1));
                    setDocuments(lDocuments);
                    setDeleteDocuments(false);
                }
            }
            else{
                
            }
        }).catch((error)=>{
        })   
    }
    
    function sendReminder(){
        const lResponse = CharismaStudentTrainingApi.SendSubscribeReminder(lStudentId, lTrainingId, lStudentTrainingId);
        lResponse.then((value)=>{
            if(value.error === 0){
                setReminderSent(true);   
            }
            else{
                
            }
        }).catch((error)=>{
        })           
    }
    
    function handleAddFile(pFile:File, pDocType : string){
        const lResponse = CharismaStudentTrainingApi.AddDocument(lStudentTrainingId, lStudentId, pFile, lType, pDocType, lTrainingId);
        lResponse.then((value)=>{
            if(value.error === 0){
                let lDocuments = documents.concat(value.document);
                setAdd(false);
                setDocuments(lDocuments); 
            }
            else{
                
            }
        }).catch((error)=>{
        })     
    }
    
    function handleCancel(){
        setAdd(false);
    }
    
    const lDocuments = documents.filter((document)=>(document.filename.toLowerCase().includes(search.toLowerCase())));

    return(
        <Box>
            <Card>
                <CardHeader
                    title={props.title}
                    action={
                        <div>
                        <IconButton onClick={()=>handleDownload(true)}>
                                <DownloadIcon/>
                        </IconButton> 
                        {                  
                            lReadWrite || (props.userProfile === 3 && lType === 'subscribe')?
                            <IconButton onClick={()=>setAdd(true)}>
                                <AddIcon/>
                            </IconButton>                        
                            :
                            null
                        }
                        {                  
                            (lReadWrite  || (props.userProfile === 3 && lType === 'subscribe')) && selected.length > 0?
                            <IconButton onClick={()=>setDeleteDocuments(true)}>
                                <DeleteIcon/>
                            </IconButton>                        
                            :
                            null
                        } 
                        </div>                             
                    }
                />
                <CardContent>
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Rechercher…"
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(event)=>setSearch(event.target.value)}
                            value={search}
                        />
                    </Search>
                    <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {(lReadWrite && (props.userProfile !== 3 || lType !== 'personal')) || (props.userProfile === 3 && lType === 'subscribe')?
                                    <TableCell padding="checkbox">
                                    </TableCell>
                                    :
                                    null
                                }
                                <TableCell>Nom</TableCell>
                                {lType === 'subscribe' ?
                                    <TableCell>
                                        Type
                                    </TableCell>
                                    :
                                    null
                                }
                                {lType === 'subscribe' ?
                                    <TableCell sx={{display:{xs:'none', md:'table-cell'}}}>Mis à jour le</TableCell>
                                    :
                                    null
                                }
                                {lType === 'subscribe' ?
                                    <TableCell>
                                        Validé
                                    </TableCell>
                                    :
                                    null
                                }                                
                            </TableRow>
                        </TableHead>
                        <TableBody>                      
                        {
                            lDocuments.map((document)=>(
                                <TableRow key={document.filename}>
                                    {(lReadWrite && (props.userProfile !== 3 || lType !== 'personal')) || (props.userProfile === 3 && lType === 'subscribe') ?
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selected.indexOf(document.id) >= 0}
                                                onClick={(event)=>handleSelectedDocument(event, document.id)}
                                            />
                                        </TableCell>
                                        :
                                        null
                                    }
                                    <TableCell>
                                        <Link to={document.url} target="_blank" rel="noopener noreferrer" >
                                            {document.filename}
                                        </Link>
                                    </TableCell>
                                    {lType === 'subscribe' ?
                                        <TableCell>
                                            {document.docType}
                                        </TableCell>
                                        :
                                        null
                                    }
                                    {lType === 'subscribe' ?
                                        <TableCell sx={{display:{xs:'none', md:'table-cell'}}}>
                                            {formatDate(document.updated)}
                                        </TableCell>
                                        :
                                        null
                                    }
                                    {lType === 'subscribe' ?
                                        <TableCell>
                                            <Checkbox
                                                disabled = {lCurrentUser.profile !== 0}
                                                checked = {document.valid}
                                                onClick={(event)=>handleValid(event, document.id)}
                                            />
                                        </TableCell>
                                        :
                                        null
                                    }
                                </TableRow>
                            ))
                        }                                              
                        </TableBody>
                    </Table>    
                    </TableContainer>    
                    {                  
                        lReadWrite && lType === 'subscribe'?
                        <Button onClick={()=>sendReminder()}>
                            Envoyer un mail de rappel
                        </Button>                        
                        :
                        null
                    }        
                </CardContent>
            </Card>  
            {
                add?
                <CharismaStudentTrainingDocsForm
                    handleCancel={handleCancel}
                    handleSubmit={handleAddFile}
                    fileType={'pdf'}
                    type = {lType}
                    docTypes={docTypes}
                    title="Ajout d'un document"
                />
                :
                null
            }
            <Dialog
                open={deleteDocuments}
            >
                <DialogTitle>Suppression de Documents</DialogTitle>
                <DialogContent>
                    <Typography>Etes-vous sûr de vouloir supprimer définitivement les documents sélectionnés?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setDeleteDocuments(false)}>
                        Annuler
                    </Button>
                    <Button onClick={handleDeleteDocuments}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={reminderSent}
            >
                <DialogContent>
                    <Alert severity="info">Mail de rappel envoyé</Alert>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setReminderSent(false)}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={download}
            >
                <DialogTitle>Télécharger les documents</DialogTitle>
                <DialogContent>
                    {link === null ?
                        <Typography>Création de l'archive en cours...</Typography>
                        :
                        <Link to={link} download target="_blank">Télécharger</Link>
                    }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setDownload(false)}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>         
        </Box>      
    );
}